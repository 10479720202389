<template>
    <nav class="header">
        <div class="bar">
            <a
                v-if="
                    !/^\/bestellen(\/ueberpruefen|\?.*)/.test($route.fullPath)
                "
                href="https://minimist.de/recyclehero"
            >
                <div
                    class="bg-red-400 flex flex-row items-center justify-center text-white animate-pulse"
                >
                    <b>UNSER HERO STORE IST JETZT LIVE!</b>
                    <!-- <p class="ml-4">
                        Jetzt nach nachhaltiger und stilvoller Kleidung stöbern.
                    </p> -->
                    <p class="ml-4">
                        <b>Klicke hier</b> und sichere dir jetzt mit dem Code
                        <b>WELCOME</b> 20% auf deinen Einkauf.
                    </p>
                </div>
            </a>
            <div class="desktop">
                <div class="shadow-bar"></div>
                <logo />
                <div class="page-links mr-auto">
                    <div
                        v-for="link in links"
                        :key="link.name"
                        class="page-link"
                    >
                        <linky
                            :to="
                                link.externalUrl
                                    ? link.externalUrl
                                    : { name: link.name }
                            "
                            target="_self"
                            class="white"
                            aria-current="page"
                            >{{ i18n.routes[link.name] }}
                        </linky>
                        <div v-if="link.sublinks" class="desktop-drop">
                            <linky
                                v-for="sublink in link.sublinks"
                                :key="sublink.name"
                                :to="{ name: sublink.name }"
                                class="white"
                                aria-current="page"
                                @click="showMenu = false"
                            >
                                {{ i18n.routes[sublink.name] }}
                            </linky>
                        </div>
                    </div>
                    <linky :to="'https://minimist.de/recyclehero'" class="white"
                        >Shop</linky
                    >
                </div>
                <div class="extra">
                    <div
                        :class="[
                            'user-area',
                            { authenticated: userStore.authenticated },
                        ]"
                    >
                        <div class="user-dashboard">
                            <butty
                                class="accent ghost small"
                                :to="{ name: 'user-dashboard' }"
                                >Kundenbereich</butty
                            >
                        </div>
                        <div class="login">
                            <butty
                                class="accent ghost small"
                                :to="{ name: 'login' }"
                                >Login</butty
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="mobile">
                <div class="shadow-bar"></div>
                <logo />
                <navigation-burger
                    class="ml-auto"
                    :active="showMenu"
                    @click.prevent.stop="toggleMenu()"
                />
            </div>
        </div>

        <div ref="content" :class="['content', { show: showMenu }]">
            <div class="page-links">
                <template v-for="link in links" :key="link.name">
                    <template v-if="link.sublinks">
                        <dropdown :show="showMenu">
                            <template #header>
                                <linky
                                    :to="{ name: link.name }"
                                    class=""
                                    aria-current="page"
                                    @click="showMenu = false"
                                    >{{ i18n.routes[link.name] }}</linky
                                >
                            </template>
                            <template #dropContent>
                                <div class="dropContentClass">
                                    <linky
                                        v-for="sublink in link.sublinks"
                                        :key="sublink.name"
                                        :to="{ name: sublink.name }"
                                        class=""
                                        aria-current="page"
                                        @click="showMenu = false"
                                        >{{ i18n.routes[sublink.name] }}</linky
                                    >
                                </div>
                            </template>
                        </dropdown>
                    </template>
                    <linky
                        v-else
                        :to="{ name: link.name }"
                        class=""
                        aria-current="page"
                        @click="showMenu = false"
                        >{{ i18n.routes[link.name] }}</linky
                    >
                </template>

                <linky
                    v-if="!userStore.authenticated"
                    :to="{ name: 'user-dashboard' }"
                    class="user-dashboard"
                    aria-current="page"
                    @click="showMenu = false"
                >
                    <icon name="avatar" /> Kundenbereich</linky
                >
            </div>

            <div v-if="userStore.authenticated" class="page-links user-area">
                <linky
                    v-for="link in userAreaLinks"
                    :key="link.name"
                    :to="{ name: link.name }"
                    class=""
                    aria-current="page"
                    @click="showMenu = false"
                    >{{ i18n.routes[link.name] }}</linky
                >
                <linky
                    class=""
                    @click="
                        () => {
                            showMenu = false
                            userStore.logout()
                        }
                    "
                    >Abmelden</linky
                >

                <icon name="avatar" />
            </div>
        </div>

        <div
            :class="['shadow', { show: showMenu }]"
            @click.prevent.stop="showMenu = false"
        ></div>
    </nav>
</template>

<script>
import useUserStore from '~/stores/user.js'
import routes from '@/routes'

export default {
    async setup() {
        const [userStore, i18n] = await Promise.all([useUserStore(), useI18n()])

        return {
            userStore,
            i18n,
        }
    },

    data() {
        const flatLinks = routes.filter((item) =>
            [
                'old-clothes',
                'electronics',
                'faq',
                'jobs',
                'social-projects',
                'blog',
                'contact',
            ].includes(item.name),
        )

        /* add sublinks for old-clothes, like:
         * {
         *  name: 'old-clothes',
         *  path: '/altkleider',
         *  sublinks: [
         *      {
         *          name: 'old-clothes-cologne',
         *          path: '/altkleider/koeln',
         *      },
         *      {
         *          name: 'old-clothes-hamburg',
         *          path: '/altkleider/hamburg',
         *      },
         *  ]
         * },
         */
        const links = this.addSubroutesWithPrefix(flatLinks, 'old-clothes')

        const userAreaLinks = routes.filter((item) =>
            ['user-dashboard', 'user-data', 'user-invoices'].includes(
                item.name,
            ),
        )

        return {
            links,
            userAreaLinks,

            showMenu: false,
        }
    },

    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu
        },

        addSubroutesWithPrefix(flatLinks, prefix) {
            const subroutes = routes.filter((item) =>
                item.name.includes(prefix + '-'),
            )
            const links = flatLinks.map((link) => {
                if (link.name === prefix) {
                    return {
                        ...link,
                        sublinks: subroutes,
                    }
                } else {
                    return link
                }
            })

            return links
        },
    },
}
</script>

<style lang="scss" scoped>
.desktop .desktop-drop {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: calc(var(--nav-height) * 1); //0.65);//3rem;
    background: theme('colors.slate.900' / 0.9);
    padding: 0.75rem;
    z-index: 850;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
}

.desktop .page-link:hover .desktop-drop {
    display: flex;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 900;

    html.touch-device & {
        width: 100%;
    }

    > .shadow {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        display: block;
        width: 100vw;
        height: 100vh;
        background-color: theme('colors.gray.700');
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity 0.4s, visibility 0s 0.4s;

        &.show {
            opacity: 0.5;
            visibility: visible;
            pointer-events: all;
            transition-delay: 0s, 0s;
        }
    }
}

.bar {
    z-index: 10;
    display: block;
    position: relative;
    height: var(--nav-height);

    @apply max-w-screen-2xl;
    margin: 0 auto;
    background: theme('colors.slate.900' / 0.9);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
}

.content {
    position: absolute;
    z-index: 5;
    display: flex;
    flex-flow: column nowrap;
    top: 100%;
    left: 0;
    width: 100%;
    height: auto;
    max-height: calc(100vh - var(--nav-height));
    background: theme('colors.ocean.300');
    box-shadow: 0 0 0.5rem 0 theme('colors.gray.600' / 50%);
    @apply text-lg;
    @apply font-semibold;
    opacity: 0;
    transform: translate(0, -0.75rem);
    visibility: hidden;
    transition: opacity 0.4s,
        transform 0.4s theme('transitionTimingFunction.easeOutCubic'),
        visibility 0s 0.4s;

    &.show {
        opacity: 1;
        transform: translate(0, 0);
        visibility: visible;
        transition-delay: 0s, 0s, 0s;
    }

    > .inner {
        position: relative;
        width: 100%;
        overflow: hidden;
        padding-top: 1.5rem;
        padding-right: calc(var(--section-padding-x) / 2);
        padding-bottom: 0.5rem;
    }

    .page-links {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        text-align: center;
        margin: 0 auto;
        width: 100%;
        padding: 1rem var(--section-padding-x);

        &.user-area {
            background-color: theme('colors.ocean.500');

            .avatar {
                position: absolute;
                left: var(--section-padding-x);
                top: 1rem;
                display: block;
                width: 1.5rem;
                margin-right: 0.5rem;
            }
        }

        .dropContentClass {
            @apply text-sm;
            display: flex;
            flex-flow: column nowrap;
            //text-align: center;
            align-items: center;
            margin: 0 auto;
            width: 100%;
        }

        .linky {
            display: inline-block;
            color: theme('colors.slate.900');
            padding: 0.25rem 0.75rem;
            margin-top: 0.75rem;
            line-height: 1;

            &:first-child {
                //margin-top: 0;
            }

            &.user-dashboard {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;

                .avatar {
                    width: 1.5rem;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}

.shadow-bar {
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 100%;
    width: 100%;
    height: 1rem;
    overflow: hidden;
    pointer-events: none;

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 0;
        box-shadow: 0 0 5px 3px theme('colors.slate.900' / 0.35);
    }
}

.desktop {
    display: none;
    position: relative;
    flex-flow: row nowrap;
    gap: 0.75rem;
    align-items: center;
    width: 100%;
    height: 100%;
    background: theme('colors.slate.900');
    padding: 0 var(--section-padding-x);

    .page-links {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        gap: 0;
        align-items: center;
        margin-left: auto;
        height: 100%;

        .page-link {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }

    .linky {
        white-space: nowrap;
        color: #fff;
        @apply text-sm;
        padding: 0.25rem 0.75rem;
        //height: 100%;
    }

    @media (min-width: 992px) {
        .page-links {
            gap: 0.75rem;
        }
        .linky {
            padding: 0.25rem 0.25rem;
        }
    }

    @media (min-width: 1250px) {
        .page-links {
            gap: 0.5rem;
        }
        .linky {
            @apply text-md;
            padding: 0.25rem 0.75rem;
        }
    }
}

.user-area {
    position: relative;

    &.authenticated {
        .user-dashboard {
            opacity: 1;
            pointer-events: all;
            transition-delay: 0.25s;

            .butty {
                animation: button-pop 0.25s 0.25s ease-out;
            }
        }
    }

    &:not(.authenticated) {
        .login {
            opacity: 1;
            pointer-events: all;
            transition-delay: 0.25s;

            .butty {
                animation: button-pop 0.25s 0.25s ease-out;
            }
        }
    }

    .user-dashboard,
    .login {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.25s, pointer-events 0s 0.25s;

        .butty {
            animation: none;
        }
    }

    .login {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.mobile {
    display: flex;
    flex-flow: row nowrap;
    gap: 2rem;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 1rem var(--section-padding-x);
}

@media (min-width: 992px) {
    .desktop {
        display: flex;
    }

    .mobile {
        display: none;
    }
}
</style>
