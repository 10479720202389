import { Enums } from './config/Enums.js'

const routes = [
    {
        name: 'index',
        path: '/',
    },
    {
        name: 'old-clothes',
        path: '/altkleider',
    },
    // {
    //     name: 'old-clothes-berlin',
    //     path: '/altkleider/berlin',
    // },
    {
        name: 'old-clothes-frankfurt',
        path: '/altkleider/frankfurt',
    },
    {
        name: 'old-clothes-hamburg',
        path: '/altkleider/hamburg',
    },
    // {
    //     name: 'old-clothes-cologne',
    //     path: '/altkleider/koeln',
    // },
    // {
    //     name: 'old-clothes-munich',
    //     path: '/altkleider/muenchen',
    // },
    {
        name: 'electronics',
        path: '/elektrogeraete',
    },
    {
        name: 'faq',
        path: '/haeufige-fragen',
    },
    {
        name: 'team',
        path: '/team',
    },
    {
        name: 'jobs',
        path: '/jobs',
    },
    {
        name: 'social-projects',
        path: '/soziale-projekte',
    },
    {
        name: 'blog',
        path: '/blog',
        externalUrl: 'https://info.recyclehero.de/',
    },
    {
        name: 'contact',
        path: '/kontakt',
    },
    {
        name: 'legal',
        path: '/impressum',
    },
    {
        name: 'privacy',
        path: '/datenschutz',
    },
    {
        name: 'terms',
        path: '/agb',
    },
    {
        name: 'blog-article',
        path: '/blog/:slug',
        component: 'pages/blog/[slug].vue',
    },
    {
        name: 'order',
        path: '/bestellen',
    },
    {
        name: 'order-confirmation',
        path: '/bestellen/ueberpruefen',
    },
    {
        name: 'order-thank-you',
        path: '/bestellen/danke',
    },
    {
        name: 'partners-tobytiger',
        path: '/tobytiger',
        showNavigation: false,
    },
    {
        name: 'single-selfservice',
        path: '/selfservice',
    },
    {
        name: 'single-selfservice-details',
        path: '/selfservice/details',
    },
    {
        name: 'login',
        path: '/anmelden',
    },
    {
        name: 'register',
        path: '/registrieren',
    },
    {
        name: 'reset-password',
        path: '/passwort-zuruecksetzen',
    },
    {
        name: 'user-dashboard',
        path: '/kunde/uebersicht',
    },
    {
        name: 'user-data',
        path: '/kunde/daten',
    },
    {
        name: 'user-invoices',
        path: '/kunde/rechnungen',
    },
    {
        name: 'error-404',
        path: '/:pathMatch(.*)*',
    },
]

export default routes
