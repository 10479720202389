<template>
    <div class="dialog">
        <header>
            <h2>{{ title }}</h2>
        </header>

        <div v-if="!isList" class="content">
            <template v-for="line in message.split('\n')"
                ><span v-html="line"></span><br />
            </template>
        </div>
        <div v-if="isList" class="content">
            <ul class="list-disc pl-4">
                <template v-for="line in message.split('\n')"
                    ><li v-html="line" class="mb-2"></li>
                </template>
            </ul>
        </div>
        <div>
            Bei weiteren Fragen kontaktiere unseren
            <linky
                v-if="isSupportLinkRequired"
                :to="{ name: 'contact' }"
                @click="$emit('close', 'close')"
                >Support</linky
            >.
        </div>
        <div class="actions">
            <butty
                v-if="actions.includes('close')"
                class="close"
                @click="$emit('close', 'close')"
                >Schließen</butty
            >
            <butty
                v-if="actions.includes('ok')"
                class="close"
                @click="$emit('close', 'ok')"
                >OK</butty
            >
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String, default: '' },
        message: { type: String, default: '' },
        actions: { type: Array, default: () => ['close'] },
        isList: { type: Boolean, default: false },
        isSupportLinkRequired: { type: Boolean, default: false },
    },
    emits: ['close'],
}
</script>

<style lang="scss" scoped>
.dialog {
    max-width: 26rem;
}

header {
    > h2 {
        margin-bottom: 1.5rem;
    }
}

.content {
    margin: 0 0 2rem;
}

.actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
}

// @screen sm {
//     header {
//         > h2 {
//             margin-bottom: 2rem;
//         }
//     }
// }

@screen lg {
    header {
        > h2 {
            @apply text-3xl;
        }
    }
}
</style>
